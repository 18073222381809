<template>
	<div class="animated fadeIn">
		<div class="main-info">
			<div class="main-info__body">
				<h1 class="main-info__body-title">
					{{ route.routeCode }}
					<span
						v-if="!route.isDraft"
						class="main-info__body-title-status bg-success"
						>Подтвержден</span
					>
					<span v-else>
						<span
							v-if="route.isDraft"
							class="main-info__body-title-status bg-secondary"
							>Не подтвержден</span
						>
						<span
							v-if="route.isDirty"
							class="main-info__body-title-status bg-danger"
							>На верификации</span
						>
						<span
							v-if="route.isReady"
							class="main-info__body-title-status bg-success"
							>Готов к отписке</span
						>
					</span>
					<span
						v-if="route.disclaimer && route.disclaimer.status === 0"
						class="main-info__body-title-status bg-danger"
						>Заявка на отказ</span
					>
				</h1>
				<b-row v-if="route.disclaimer">
					<b-col sm="6" md="4">
						<b-card
							header="Заявка на отказ"
							border-variant="danger"
						>
							<p v-if="route.disclaimer.comment" class="mb-2">
								Вы подали заявку на отказ от рейса, указав
								следующую причину:
							</p>
							<p
								v-if="route.disclaimer.comment"
								class="mt-2 mb-3"
							>
								{{ route.disclaimer.comment }}
							</p>
							<p
								v-if="
									route.disclaimer &&
										route.disclaimer.status === 0
								"
								class="text-secondary"
							>
								Ожидайте решение логиста.
							</p>
							<p
								v-if="
									route.disclaimer &&
										route.disclaimer.status === 2
								"
								class="text-secondary"
							>
								Ваша заявка была отклонена.
							</p>
						</b-card>
					</b-col>
				</b-row>
				<div v-if="route.auction">
					<h5 class="main-info__body-subtitle">
						Аукцион
					</h5>
					<b-row>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Габариты
								</p>
								<p class="main-info__item-desc">
									{{ form.size }}
								</p>
							</div>
							<div class="main-info__item">
								<p class="main-info__item-title">
									Дополнительные габариты
								</p>
								<p
									v-for="optionalDimension in optionalDimensions"
									:key="optionalDimension.id"
									:value="optionalDimension.id"
									class="main-info__item-desc"
								>
									{{ optionalDimension.dimension.name }}
								</p>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Погрузка/Выгрузка
									<i
										v-if="form.loadingUnloading.length > 1"
										class="main-info__item-title-note"
										>(один из вариантов)</i
									>
								</p>
								<ul class="main-info__item-list">
									<li
										v-for="item in form.loadingUnloading"
										:key="`loading-unloading-${item.id}`"
										class="main-info__item-list-item"
									>
										{{ item.name }}
									</li>
								</ul>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Тип кузова
									<i
										v-if="form.bodyType.length > 1"
										class="main-info__item-title-note"
										>(один из вариантов)</i
									>
								</p>
								<ul class="main-info__item-list">
									<li
										v-for="item in form.bodyType"
										:key="`body-type-${item.id}`"
										class="main-info__item-list-item"
									>
										{{ item.name }}
									</li>
								</ul>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Обязательное наличие пропуска на МКАД
								</p>
								<p class="main-info__item-desc">
									<b-form-checkbox
										v-model="form.mkadPass"
										disabled
										name="mkadPass"
									>
										Да
									</b-form-checkbox>
								</p>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Обязательная постановка ранее допущенного
									водителя
								</p>
								<p class="main-info__item-desc">
									<b-form-checkbox
										v-model="form.retryDriver"
										disabled
										name="retryDriver"
									>
										Да
									</b-form-checkbox>
								</p>
							</div>
						</b-col>
					</b-row>
				</div>
				<div>
					<h5 class="main-info__body-subtitle">
						Детали рейса
					</h5>
					<b-row>
						<b-col v-if="route.routeCode" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Код рейса
								</p>
								<p class="main-info__item-desc">
									{{ route.routeCode }}
								</p>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Стоимость рейса
								</p>
								<p class="main-info__item-desc">
									{{ numberRUFormat(form.freightSumm) }} руб.
								</p>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Грузопровод
								</p>
								<b-form-checkbox
									id="cargoFlow"
									v-model="form.cargoFlow"
									name="cargoFlow"
									:disabled="true"
								>
									Да
								</b-form-checkbox>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Тип перевозки
								</p>
								<p class="main-info__item-desc">
									{{ route.transportation.name }}
								</p>
							</div>
						</b-col>
						<b-col v-if="route.fullDistance" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Общее расстояние
								</p>
								<p class="main-info__item-desc">
									{{ route.fullDistance }} км.
								</p>
							</div>
						</b-col>
						<b-col v-if="!form.cargoFlow" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Ускоренный
								</p>
								<b-form-checkbox
									id="accelerated"
									v-model="form.accelerated"
									name="accelerated"
									:disabled="true"
								>
									Да
								</b-form-checkbox>
							</div>
						</b-col>
					</b-row>
					<h5 class="main-info__body-subtitle">
						Ответственные
					</h5>
					<b-row>
						<b-col v-if="data.responsible.name" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Группа ответственных
								</p>
								<p class="main-info__item-desc">
									<span v-if="data.responsible.name">{{
										data.responsible.name
									}}</span>
									<span v-else>Н/Д</span>
								</p>
							</div>
						</b-col>
						<b-col v-if="data.responsible.agent" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Контактные данные
								</p>
								<p class="main-info__item-desc">
									{{ data.responsible.agent.fullname }}
								</p>
								<div v-if="data.responsible.agent.userPhones">
									<p
										v-if="
											data.responsible.agent.userPhones
												.length
										"
									>
										Телефоны
									</p>
									<ul
										v-if="
											data.responsible.agent.userPhones
												.length
										"
									>
										<li
											v-for="phone in data.responsible
												.agent.userPhones"
											:key="phone.id"
										>
											<span v-if="phone.name">
												<span v-if="phone.name"
													>{{ phone.name }}:</span
												>
											</span>
											<a
												:href="
													'tel:' +
														phone.countryCode.code +
														phone.areaCode +
														phone.localNumber +
														phone.additionalNumber
												"
											>
												{{
													phone.countryCode.code +
														phone.areaCode +
														phone.localNumber
												}}
												<span
													v-if="
														phone.additionalNumber
													"
												>
													{{ phone.additionalNumber }}
												</span>
											</a>
										</li>
									</ul>
								</div>
								<p v-if="data.responsible.agent.email">
									Email:
									<a
										:href="
											'mailto:' +
												data.responsible.agent.email
										"
										>{{ data.responsible.agent.email }}</a
									>
								</p>
							</div>
						</b-col>
						<b-col v-if="form.organizations.value" md="3">
							<p class="main-info__item-title">
								Организация
							</p>
							<b-form-group
								id="organizationsInputGroup"
								class="form-group-el-select"
								label-for="organizationSelect"
							>
								<span>{{ form.organizations.name }}</span>
							</b-form-group>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Перевозчик
								</p>
								<p class="main-info__item-desc">
									{{ form.contractor.name }}
								</p>
							</div>
						</b-col>
					</b-row>
					<h5 v-if="!form.cargoFlow" class="main-info__body-subtitle">
						Водители
					</h5>
					<b-row v-if="!form.cargoFlow">
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Водитель 1
								</p>
								<div
									v-if="form.driverOne.value"
									class="main-info__item-info-card"
								>
									<p class="main-info__item-info-card-title">
										{{ data.driverOne.fio }}
									</p>
									<ul class="main-info__item-info-card-list">
										<li
											v-if="data.driverOne.inn"
											class="main-info__item-info-card-list-item"
										>
											ИНН: {{ data.driverOne.inn }}
										</li>
										<li
											v-if="
												data.driverOne.driverPhones
													.length
											"
											class="main-info__item-info-card-list-item"
										>
											Телефоны:
											<span
												v-for="(phone, index) in data
													.driverOne.driverPhones"
												:key="index"
											>
												<span v-if="phone.name"
													>{{ phone.name }}:</span
												>
												<a
													:href="
														'tel:' + phone.number
													"
													>{{ phone.number }}</a
												>;
											</span>
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Допуск:
											<span
												v-if="
													data.driverOne
														.driverAccessAuto
												"
												>Авто;
											</span>
											<span
												v-if="
													data.driverOne
														.driverAccessAuto ||
														data.driverOne
															.driverAccessCargoFlow
												"
												>Контейнер;
											</span>
											<span
												v-if="
													data.driverOne
														.driverAccessAuto ||
														data.driverOne
															.driverAccessContainer
												"
												>Грузопровод;
											</span>
										</li>
										<li
											v-if="
												data.driverOne.attorneys.length
											"
											class="main-info__item-info-card-list-item"
										>
											Доверенности:
											{{
												data.driverOne.attorneys.length
											}}
											<label
												class="main-info__item-info-card-switcher"
											>
												<input
													v-model="
														data.driverOne.switcher
													"
													type="checkbox"
													class="main-info__item-info-card-switcher-input"
												/>
												<span
													class="main-info__item-info-card-switcher-display"
												>
													<span
														v-if="
															data.driverOne
																.switcher
														"
														>Скрыть подробности<i
															class="main-info__item-info-card-switcher-display-icon fa fa-arrow-up"
													/></span>
													<span v-else
														>Показать подробности<i
															class="main-info__item-info-card-switcher-display-icon fa fa-arrow-down"
													/></span>
												</span>
											</label>
										</li>
										<ol
											v-if="
												data.driverOne.switcher &&
													data.driverOne.attorneys
														.length
											"
											class="main-info__item-info-card-list"
										>
											<li
												v-for="(attorney, index) in data
													.driverOne.attorneys"
												:key="index"
												class="main-info__item-info-card-list-item"
											>
												<a
													:href="
														`/contractors/id${form.contractor.value}/attorney/id${attorney.id}`
													"
													target="_blank"
													>Доверенность №{{
														attorney.number
													}}</a
												>
												<span
													v-if="attorney.status === 0"
													class="main-info__item-info-card-list-item-status bg-secondary"
												>
													На рассмотрении</span
												>
												<span
													v-else-if="
														attorney.status === 1
													"
													class="main-info__item-info-card-list-item-status bg-success"
												>
													Действует</span
												>
												<span
													v-else-if="
														attorney.status === 2
													"
													class="main-info__item-info-card-list-item-status bg-danger"
												>
													Аннулирована</span
												><br />
												Действует с
												{{ attorney.dateStart }} по
												{{ attorney.dateEnd }}<br />
												<span v-if="attorney.file">
													Загруженный файл:
													<a
														:href="
															'/files/' +
																attorney.file
																	.file.guid
														"
														target="_blank"
														@click.prevent="
															downloadFile(
																attorney.file
																	.file
															)
														"
														>{{
															attorney.file.file
																.originalName
														}}</a
													>
												</span>
											</li>
										</ol>
									</ul>
								</div>
								<p v-else>
									Н/Д
								</p>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Водитель 2
								</p>
								<div
									v-if="form.driverTwo.value"
									class="main-info__item-info-card"
								>
									<p class="main-info__item-info-card-title">
										{{ data.driverTwo.fio }}
									</p>
									<ul class="main-info__item-info-card-list">
										<li
											v-if="data.driverOne.inn"
											class="main-info__item-info-card-list-item"
										>
											ИНН: {{ data.driverOne.inn }}
										</li>
										<li
											v-if="
												data.driverTwo.driverPhones
													.length
											"
											class="main-info__item-info-card-list-item"
										>
											Телефоны:
											<span
												v-for="(phone, index) in data
													.driverTwo.driverPhones"
												:key="index"
											>
												<span v-if="phone.name"
													>{{ phone.name }}:</span
												>
												<a
													:href="
														'tel:' + phone.number
													"
													>{{ phone.number }}</a
												>;
											</span>
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Допуск:
											<span
												v-if="
													data.driverTwo
														.driverAccessAuto
												"
												>Авто;
											</span>
											<span
												v-if="
													data.driverTwo
														.driverAccessAuto ||
														data.driverTwo
															.driverAccessCargoFlow
												"
												>Контейнер;
											</span>
											<span
												v-if="
													data.driverTwo
														.driverAccessAuto ||
														data.driverTwo
															.driverAccessContainer
												"
												>Грузопровод;
											</span>
										</li>
										<li
											v-if="
												data.driverTwo.attorneys.length
											"
											class="main-info__item-info-card-list-item"
										>
											Доверенности:
											{{
												data.driverTwo.attorneys.length
											}}
											<label
												class="main-info__item-info-card-switcher"
											>
												<input
													v-model="
														data.driverTwo.switcher
													"
													type="checkbox"
													class="main-info__item-info-card-switcher-input"
												/>
												<span
													class="main-info__item-info-card-switcher-display"
												>
													<span
														v-if="
															data.driverTwo
																.switcher
														"
														>Скрыть подробности<i
															class="main-info__item-info-card-switcher-display-icon fa fa-arrow-up"
													/></span>
													<span v-else
														>Показать подробности<i
															class="main-info__item-info-card-switcher-display-icon fa fa-arrow-down"
													/></span>
												</span>
											</label>
										</li>
										<ol
											v-if="
												data.driverTwo.switcher &&
													data.driverTwo.attorneys
														.length
											"
											class="main-info__item-info-card-list"
										>
											<li
												v-for="(attorney, index) in data
													.driverTwo.attorneys"
												:key="index"
												class="main-info__item-info-card-list-item"
											>
												<a
													:href="
														`/contractors/id${form.contractor.value}/attorney/id${attorney.id}`
													"
													target="_blank"
													>Доверенность №{{
														attorney.number
													}}</a
												>
												<span
													v-if="attorney.status === 0"
													class="main-info__item-info-card-list-item-status bg-secondary"
												>
													На рассмотрении</span
												>
												<span
													v-else-if="
														attorney.status === 1
													"
													class="main-info__item-info-card-list-item-status bg-success"
												>
													Действует</span
												>
												<span
													v-else-if="
														attorney.status === 2
													"
													class="main-info__item-info-card-list-item-status bg-danger"
												>
													Аннулирована</span
												><br />
												Действует с
												{{ attorney.dateStart }} по
												{{ attorney.dateEnd }}<br />
												<span v-if="attorney.file">
													Загруженный файл:
													<a
														:href="
															'/files/' +
																attorney.file
																	.file.guid
														"
														target="_blank"
														@click.prevent="
															downloadFile(
																attorney.file
																	.file
															)
														"
														>{{
															attorney.file.file
																.originalName
														}}</a
													>
												</span>
											</li>
										</ol>
									</ul>
								</div>
								<p v-else>
									Н/Д
								</p>
							</div>
						</b-col>
					</b-row>
					<h5 class="main-info__body-subtitle">
						Транспорт
					</h5>
					<b-row>
						<b-col v-if="!form.cargoFlow" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Автомобиль
								</p>
								<div
									v-if="form.car.value"
									class="main-info__item-info-card"
								>
									<p class="main-info__item-info-card-title">
										{{ data.car.name }},
										{{ data.car.registrationNumber }}
									</p>
									<ul
										v-if="data.car.type === 'Тягач'"
										class="main-info__item-info-card-list"
									>
										<li
											class="main-info__item-info-card-list-item"
										>
											Тип ТС: {{ data.car.type }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Грузоподъемность:
											{{ data.car.loadCapacity }}
											т
										</li>
									</ul>
									<ul
										v-else
										class="main-info__item-info-card-list"
									>
										<li
											class="main-info__item-info-card-list-item"
										>
											Тип ТС: {{ data.car.type }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Грузоподъемность:
											{{ data.car.loadCapacity }}
											т
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Контейнеровоз:
											<span
												v-if="
													data.car
														.containerTransportVehicle
												"
												>Да</span
											>
											<span v-else>Нет</span>
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Высота кузова:
											{{ data.car.bodyHeight }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Ширина кузова:
											{{ data.car.bodyWidth }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Глубина кузова:
											{{ data.car.bodyDepth }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Объем кузова:
											{{ data.car.bodyVolume }}
										</li>
										<li
											v-if="data.car.bodyType"
											class="main-info__item-info-card-list-item"
										>
											Тип кузова: {{ data.car.bodyType }}
										</li>
									</ul>
								</div>
								<p v-else>
									Н/Д
								</p>
							</div>
							<div
								v-if="
									form.car.value &&
										data.car.containerTransportVehicle
								"
								class="main-info__item"
							>
								<p class="main-info__item-title">
									Контейнер автомобиля
								</p>
								<div v-if="form.carContainer.value.length">
									<div
										v-for="(container,
										index) in data.carContainer"
										:key="`car-container-${index}`"
										class="main-info__item-info-card"
									>
										<p
											class="main-info__item-info-card-title"
										>
											{{ container.name }},
											{{ container.number }}
										</p>
										<ul
											class="main-info__item-info-card-list"
										>
											<li
												class="main-info__item-info-card-list-item"
											>
												Высота: {{ container.height }}
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Ширина: {{ container.width }}
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Глубина: {{ container.depth }}
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Объем: {{ container.volume }}
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Грузоподъемность:
												{{ container.maximumPayload }}
											</li>
										</ul>
									</div>
								</div>
								<p v-else>
									Н/Д
								</p>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Прицеп
								</p>
								<div
									v-if="form.trailer.value"
									class="main-info__item-info-card"
								>
									<p class="main-info__item-info-card-title">
										{{ data.trailer.name }},
										{{ data.trailer.registrationNumber }}
									</p>
									<ul class="main-info__item-info-card-list">
										<li
											class="main-info__item-info-card-list-item"
										>
											Тип ТС: {{ data.trailer.type }}
										</li>
										<li
											v-if="
												data.trailer
													.loadCapacity
											"
											class="main-info__item-info-card-list-item"
										>
											Грузоподъемность:
											{{
												data.trailer
													.loadCapacity
											}}
											т
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Контейнеровоз:
											<span
												v-if="
													data.trailer
														.containerTransportVehicle
												"
												>Да</span
											>
											<span v-else>Нет</span>
										</li>
										<li
											v-if="data.trailer.bodyHeight"
											class="main-info__item-info-card-list-item"
										>
											Высота кузова:
											{{ data.trailer.bodyHeight }}
										</li>
										<li
											v-if="data.trailer.bodyWidth"
											class="main-info__item-info-card-list-item"
										>
											Ширина кузова:
											{{ data.trailer.bodyWidth }}
										</li>
										<li
											v-if="data.trailer.bodyDepth"
											class="main-info__item-info-card-list-item"
										>
											Глубина кузова:
											{{ data.trailer.bodyDepth }}
										</li>
										<li
											v-if="data.trailer.bodyVolume"
											class="main-info__item-info-card-list-item"
										>
											Объем кузова:
											{{ data.trailer.bodyVolume }}
										</li>
										<li
											v-if="data.trailer.bodyType"
											class="main-info__item-info-card-list-item"
										>
											Тип кузова:
											{{ data.trailer.bodyType }}
										</li>
									</ul>
								</div>
							</div>
							<div
								v-if="
									form.trailer.value &&
										data.trailer.containerTransportVehicle
								"
								class="main-info__item"
							>
								<div class="main-info__item">
									<p class="main-info__item-title">
										Контейнер прицепа
									</p>
									<div
										v-if="
											form.trailerContainer.value.length
										"
									>
										<div
											v-for="(container,
											index) in data.trailerContainer"
											:key="`car-container-${index}`"
											class="main-info__item-info-card"
										>
											<p
												class="main-info__item-info-card-title"
											>
												{{ container.name }},
												{{ container.number }}
											</p>
											<ul
												class="main-info__item-info-card-list"
											>
												<li
													class="main-info__item-info-card-list-item"
												>
													Высота:
													{{ container.height }}
												</li>
												<li
													class="main-info__item-info-card-list-item"
												>
													Ширина:
													{{ container.width }}
												</li>
												<li
													class="main-info__item-info-card-list-item"
												>
													Глубина:
													{{ container.depth }}
												</li>
												<li
													class="main-info__item-info-card-list-item"
												>
													Объем:
													{{ container.volume }}
												</li>
												<li
													class="main-info__item-info-card-list-item"
												>
													Грузоподъемность:
													{{
														container.maximumPayload
													}}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
					<div v-if="!form.cargoFlow">
						<h5 class="main-info__body-subtitle">
							Маршрут
						</h5>
						<b-row>
							<b-col v-if="form.firstPointArrivalDatetime" md="6">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Плановая дата и время прибытия в первую
										точку маршрута
									</p>
									<p class="main-info__item-desc">
										<span class="fa fa-clock-o mr-1" />{{
											form.firstPointArrivalDatetime
										}}
										<br />
										<span class="main-info__item-desc-note"
											>Дата и время указаны в соотвествии
											с часовым поясом первой точки
											маршрута</span
										>
									</p>
								</div>
							</b-col>
							<b-col v-if="form.firstPointLoadingDatetime" md="6">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Плановая дата и время начала погрузки в
										первой точке маршрута
									</p>
									<p class="main-info__item-desc">
										<span class="fa fa-clock-o mr-1" />{{
											form.firstPointLoadingDatetime
										}}
										<br />
										<span class="main-info__item-desc-note"
											>Дата и время указаны в соотвествии
											с часовым поясом первой точки
											маршрута</span
										>
									</p>
								</div>
							</b-col>
						</b-row>
						<div class="main-info__route-info">
							<div
								v-for="(point, index) in route.points"
								:key="`point-${index}`"
								class="main-info__route-info-item"
							>
								<p class="main-info__route-info-item-title">
									<i
										class="main-info__route-info-item-title-icon fa fa-map-marker fa-lg mr-2 text-danger"
									/>
									{{ point.department.name }}
								</p>
								<div class="main-info__route-info-item-body">
									<b-row>
										<b-col md="4">
											<div class="main-info__item">
												<p
													class="main-info__item-title"
												>
													Адрес
												</p>
												<p class="main-info__item-desc">
													{{
														point.department
															.mainWarehouse
															.address
													}}
												</p>
											</div>
										</b-col>
										<b-col md="4">
											<div
												v-if="index > 0"
												class="main-info__item"
											>
												<p
													class="main-info__item-title"
												>
													Ориентировочное время в пути
												</p>
												<p class="main-info__item-desc">
													{{ point.timeSummer }}
												</p>
												<small><strong>*</strong> Точное время доставки будет указано <br> в транспортной накладной после отправки.</small>
											</div>
										</b-col>
										<b-col md="4">
											<div class="main-info__item">
												<p
													class="main-info__item-title"
												>
													Расстояние
												</p>
												<p class="main-info__item-desc">
													{{ point.distance }}
												</p>
											</div>
										</b-col>
									</b-row>
								</div>
							</div>
              <div class="mt-3 mb-4" v-if="comment.value && comment.value !== undefined">
                <b-row>
                  <b-col md="4">
                    <p class="main-info__item-title">Комментарий к рейсу</p>
                    <p class="main-info__item-desc">
                        {{comment.value}}
                    </p>
                  </b-col>
                </b-row>								
							</div>
						</div>
						<h5
							v-if="form.cargoFlow"
							class="main-info__body-subtitle"
						>
							Водители
						</h5>
						<b-row v-if="form.cargoFlow">
							<b-col md="4">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Водитель 1
									</p>
									<div
										v-if="form.driverOne.value"
										class="main-info__item-info-card"
									>
										<p
											class="main-info__item-info-card-title"
										>
											{{ data.driverOne.fio }}
										</p>
										<ul
											class="main-info__item-info-card-list"
										>
											<li
												v-if="data.driverOne.inn"
												class="main-info__item-info-card-list-item"
											>
												ИНН: {{ data.driverOne.inn }}
											</li>
											<li
												v-if="
													data.driverOne.driverPhones
														.length
												"
												class="main-info__item-info-card-list-item"
											>
												Телефоны:
												<span
													v-for="(phone,
													index) in data.driverOne
														.driverPhones"
													:key="index"
												>
													<span v-if="phone.name"
														>{{ phone.name }}:</span
													>
													<a
														:href="
															'tel:' +
																phone.number
														"
														>{{ phone.number }}</a
													>;
												</span>
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Допуск:
												<span
													v-if="
														data.driverOne
															.driverAccessAuto
													"
													>Авто;
												</span>
												<span
													v-if="
														data.driverOne
															.driverAccessAuto ||
															data.driverOne
																.driverAccessCargoFlow
													"
													>Контейнер;
												</span>
												<span
													v-if="
														data.driverOne
															.driverAccessAuto ||
															data.driverOne
																.driverAccessContainer
													"
													>Грузопровод;
												</span>
											</li>
											<li
												v-if="
													data.driverOne.attorneys
														.length
												"
												class="main-info__item-info-card-list-item"
											>
												Доверенности:
												{{
													data.driverOne.attorneys
														.length
												}}
												<label
													class="main-info__item-info-card-switcher"
												>
													<input
														v-model="
															data.driverOne
																.switcher
														"
														type="checkbox"
														class="main-info__item-info-card-switcher-input"
													/>
													<span
														class="main-info__item-info-card-switcher-display"
													>
														<span
															v-if="
																data.driverOne
																	.switcher
															"
															>Скрыть
															подробности<i
																class="main-info__item-info-card-switcher-display-icon fa fa-arrow-up"
														/></span>
														<span v-else
															>Показать
															подробности<i
																class="main-info__item-info-card-switcher-display-icon fa fa-arrow-down"
														/></span>
													</span>
												</label>
											</li>
											<ol
												v-if="
													data.driverOne.switcher &&
														data.driverOne.attorneys
															.length
												"
												class="main-info__item-info-card-list"
											>
												<li
													v-for="(attorney,
													index) in data.driverOne
														.attorneys"
													:key="index"
													class="main-info__item-info-card-list-item"
												>
													<a
														:href="
															`/contractors/id${form.contractor.id}/attorney/id${attorney.id}`
														"
														target="_blank"
														>Доверенность №{{
															attorney.number
														}}</a
													>
													<span
														v-if="
															attorney.status ===
																0
														"
														class="main-info__item-info-card-list-item-status bg-secondary"
													>
														На рассмотрении</span
													>
													<span
														v-else-if="
															attorney.status ===
																1
														"
														class="main-info__item-info-card-list-item-status bg-success"
													>
														Действует</span
													>
													<span
														v-else-if="
															attorney.status ===
																2
														"
														class="main-info__item-info-card-list-item-status bg-danger"
													>
														Аннулирована</span
													><br />
													Действует с
													{{ attorney.dateStart }} по
													{{ attorney.dateEnd }}<br />
													<span v-if="attorney.file">
														Загруженный файл:
														<a
															:href="
																'/files/' +
																	attorney
																		.file
																		.file
																		.guid
															"
															target="_blank"
															@click.prevent="
																downloadFile(
																	attorney
																		.file
																		.file
																)
															"
															>{{
																attorney.file
																	.file
																	.originalName
															}}</a
														>
													</span>
												</li>
											</ol>
										</ul>
									</div>
								</div>
							</b-col>
							<b-col md="4">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Водитель 2
									</p>
									<div
										v-if="form.driverTwo.value"
										class="main-info__item-info-card"
									>
										<p
											class="main-info__item-info-card-title"
										>
											{{ data.driverTwo.fio }}
										</p>
										<ul
											class="main-info__item-info-card-list"
										>
											<li
												v-if="data.driverTwo.inn"
												class="main-info__item-info-card-list-item"
											>
												ИНН: {{ data.driverTwo.inn }}
											</li>
											<li
												v-if="
													data.driverTwo.driverPhones
														.length
												"
												class="main-info__item-info-card-list-item"
											>
												Телефоны:
												<span
													v-for="(phone,
													index) in data.driverTwo
														.driverPhones"
													:key="index"
												>
													<span v-if="phone.name"
														>{{ phone.name }}:</span
													>
													<a
														:href="
															'tel:' +
																phone.number
														"
														>{{ phone.number }}</a
													>;
												</span>
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Допуск:
												<span
													v-if="
														data.driverTwo
															.driverAccessAuto
													"
													>Авто;
												</span>
												<span
													v-if="
														data.driverTwo
															.driverAccessAuto ||
															data.driverTwo
																.driverAccessCargoFlow
													"
													>Контейнер;
												</span>
												<span
													v-if="
														data.driverTwo
															.driverAccessAuto ||
															data.driverTwo
																.driverAccessContainer
													"
													>Грузопровод;
												</span>
											</li>
											<li
												v-if="
													data.driverTwo.attorneys
														.length
												"
												class="main-info__item-info-card-list-item"
											>
												Доверенности:
												{{
													data.driverTwo.attorneys
														.length
												}}
												<label
													class="main-info__item-info-card-switcher"
												>
													<input
														v-model="
															data.driverTwo
																.switcher
														"
														type="checkbox"
														class="main-info__item-info-card-switcher-input"
													/>
													<span
														class="main-info__item-info-card-switcher-display"
													>
														<span
															v-if="
																data.driverTwo
																	.switcher
															"
															>Скрыть
															подробности<i
																class="main-info__item-info-card-switcher-display-icon fa fa-arrow-up"
														/></span>
														<span v-else
															>Показать
															подробности<i
																class="main-info__item-info-card-switcher-display-icon fa fa-arrow-down"
														/></span>
													</span>
												</label>
											</li>
											<ol
												v-if="
													data.driverTwo.switcher &&
														data.driverTwo.attorneys
															.length
												"
												class="main-info__item-info-card-list"
											>
												<li
													v-for="(attorney,
													index) in data.driverTwo
														.attorneys"
													:key="index"
													class="main-info__item-info-card-list-item"
												>
													<a
														:href="
															`/contractors/id${form.contractor.id}/attorney/id${attorney.id}`
														"
														target="_blank"
														>Доверенность №{{
															attorney.number
														}}</a
													>
													<span
														v-if="
															attorney.status ===
																0
														"
														class="main-info__item-info-card-list-item-status bg-secondary"
													>
														На рассмотрении</span
													>
													<span
														v-else-if="
															attorney.status ===
																1
														"
														class="main-info__item-info-card-list-item-status bg-success"
													>
														Действует</span
													>
													<span
														v-else-if="
															attorney.status ===
																2
														"
														class="main-info__item-info-card-list-item-status bg-danger"
													>
														Аннулирована</span
													><br />
													Действует с
													{{ attorney.dateStart }} по
													{{ attorney.dateEnd }}<br />
													<span v-if="attorney.file">
														Загруженный файл:
														<a
															:href="
																'/files/' +
																	attorney
																		.file
																		.file
																		.guid
															"
															target="_blank"
															@click.prevent="
																downloadFile(
																	attorney
																		.file
																		.file
																)
															"
															>{{
																attorney.file
																	.file
																	.originalName
															}}</a
														>
													</span>
												</li>
											</ol>
										</ul>
									</div>
									<p v-else>
										Н/Д
									</p>
								</div>
							</b-col>
						</b-row>
					</div>
					<div v-else>
						<h5 class="main-info__body-subtitle">
							Маршрут
						</h5>
						<b-row>
							<b-col v-if="form.firstPointArrivalDatetime" md="6">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Плановая дата и время прибытия в первую
										точку маршрута
									</p>
									<p class="main-info__item-desc">
										<span class="fa fa-clock-o mr-1" />{{
											form.firstPointArrivalDatetime
										}}
										<br />
										<span class="main-info__item-desc-note"
											>Дата и время указаны в соотвествии
											с часовым поясом первой точки
											маршрута</span
										>
									</p>
								</div>
							</b-col>
							<b-col v-if="form.firstPointLoadingDatetime" md="6">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Плановая дата и время начала погрузки в
										первой точке маршрута
									</p>
									<p class="main-info__item-desc">
										<span class="fa fa-clock-o mr-1" />{{
											form.firstPointLoadingDatetime
										}}
										<br />
										<span class="main-info__item-desc-note"
											>Дата и время указаны в соотвествии
											с часовым поясом первой точки
											маршрута</span
										>
									</p>
								</div>
							</b-col>
						</b-row>
						<h5 class="main-info__body-subtitle">
							Грузопровод
						</h5>
						<div
							v-if="form.cargoPipelines.length > 0"
							class="main-info__cargo-pipelines-info"
						>
							<div
								v-for="(cargoPipeline,
								indexCargoPipeline) in form.cargoPipelines"
								:key="`point-${indexCargoPipeline}`"
								class="main-info__cargo-pipelines-info-item"
							>
								<div
									class="main-info__cargo-pipelines-info-item-header"
								>
									<p
										class="main-info__cargo-pipelines-info-item-title mr-2"
									>
										<i
											class="main-info__cargo-pipelines-info-item-title-icon fa fa-map-marker fa-lg mr-2 text-danger"
										/>
										{{
											cargoPipeline
												.cargoPipelinePlaceOfEvent.name
										}}
									</p>
								</div>
								<div
									class="main-info__cargo-pipelines-info-item-events"
								>
									<div
										v-for="(cargoPipelineEvent,
										indexCargoPipelineEvent) in cargoPipeline.cargoPipelineEvents"
										:key="
											`point-event-${indexCargoPipelineEvent}`
										"
										class="main-info__cargo-pipelines-info-item-event"
									>
										<div
											class="main-info__cargo-pipelines-info-item-body"
										>
											<div
												class="main-info__cargo-pipelines-info-item-body-header"
											>
												<p
													class="main-info__cargo-pipelines-info-item-subtitle mr-2"
												>
													Событие:
													{{
														cargoPipelineEvent.name
													}}
													{{
														formatDatetime(
															cargoPipelineEvent.cargoPipelineEventDate
														)
													}}
													<span
														v-if="
															cargoPipelineEvent
																.partner
																.switcher
														"
													>
														({{
															cargoPipelineEvent
																.partner.name
														}})
													</span>
												</p>
											</div>
											<b-row>
												<b-col md="6">
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title"
														>
															Автомобиль
														</p>
														<div
															v-if="
																cargoPipelineEvent
																	.vehicle
																	.value
															"
															class="main-info__item-info-card"
														>
															<p
																class="main-info__item-info-card-title"
															>
																{{
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.name
																}},
																{{
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.registrationNumber
																}}
															</p>
															<ul
																v-if="
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.type ===
																		'Тягач'
																"
																class="main-info__item-info-card-list"
															>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Тип ТС:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.type
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Грузоподъемность:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.loadCapacity
																	}}
																	т
																</li>
															</ul>
															<ul
																v-else
																class="main-info__item-info-card-list"
															>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Тип ТС:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.type
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Грузоподъемность:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.loadCapacity
																	}}
																	т
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Контейнеровоз:
																	<span
																		v-if="
																			cargoPipelineEvent
																				.vehicle
																				.data
																				.containerTransportVehicle
																		"
																		>Да</span
																	>
																	<span v-else
																		>Нет</span
																	>
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Высота
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyHeight
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Ширина
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyWidth
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Глубина
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyDepth
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Объем
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyVolume
																	}}
																</li>
																<li
																	v-if="
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyType
																	"
																	class="main-info__item-info-card-list-item"
																>
																	Тип кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyType
																	}}
																</li>
															</ul>
														</div>
													</div>
												</b-col>
												<b-col md="6">
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title"
														>
															Водитель
														</p>
														<div
															v-if="
																cargoPipelineEvent
																	.driver
																	.value
															"
															class="main-info__item-info-card"
														>
															<p
																class="main-info__item-info-card-title"
															>
																{{
																	cargoPipelineEvent
																		.driver
																		.data
																		.fio
																}}
															</p>
															<ul
																class="main-info__item-info-card-list"
															>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	ИНН:
																	{{
																		cargoPipelineEvent
																			.driver
																			.data
																			.inn
																	}}
																</li>
															</ul>
															<ul
																v-if="
																	cargoPipelineEvent
																		.driver
																		.data
																		.driverPhones
																"
																class="main-info__item-info-card-list"
															>
																<li
																	v-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.driverPhones
																			.length
																	"
																	class="main-info__item-info-card-list-item"
																>
																	Телефоны:
																	<span
																		v-for="(phone,
																		index) in cargoPipelineEvent
																			.driver
																			.data
																			.driverPhones"
																		:key="
																			index
																		"
																	>
																		<span
																			v-if="
																				phone.name
																			"
																			>{{
																				phone.name
																			}}:</span
																		>
																		<a
																			:href="
																				'tel:' +
																					phone.number
																			"
																			>{{
																				phone.number
																			}}</a
																		>;
																	</span>
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Допуск:
																	<span
																		v-if="
																			cargoPipelineEvent
																				.driver
																				.data
																				.driverAccessAuto
																		"
																		>Авто;
																	</span>
																	<span
																		v-if="
																			cargoPipelineEvent
																				.driver
																				.data
																				.driverAccessAuto ||
																				cargoPipelineEvent
																					.driver
																					.data
																					.driverAccessCargoFlow
																		"
																		>Контейнер;
																	</span>
																	<span
																		v-if="
																			cargoPipelineEvent
																				.driver
																				.data
																				.driverAccessAuto ||
																				cargoPipelineEvent
																					.driver
																					.data
																					.driverAccessContainer
																		"
																		>Грузопровод;
																	</span>
																</li>
																<li
																	v-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.attorneys
																			.length
																	"
																	class="main-info__item-info-card-list-item"
																>
																	Доверенности:
																	{{
																		cargoPipelineEvent
																			.driver
																			.data
																			.attorneys
																			.length
																	}}
																	<label
																		class="main-info__item-info-card-switcher"
																	>
																		<input
																			v-model="
																				cargoPipelineEvent
																					.driver
																					.data
																					.switcher
																			"
																			type="checkbox"
																			class="main-info__item-info-card-switcher-input"
																		/>
																		<span
																			class="main-info__item-info-card-switcher-display"
																		>
																			<span
																				v-if="
																					cargoPipelineEvent
																						.driver
																						.data
																						.switcher
																				"
																				>Скрыть
																				подробности<i
																					class="main-info__item-info-card-switcher-display-icon fa fa-arrow-up"
																			/></span>
																			<span
																				v-else
																				>Показать
																				подробности<i
																					class="main-info__item-info-card-switcher-display-icon fa fa-arrow-down"
																			/></span>
																		</span>
																	</label>
																</li>
																<ol
																	v-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.switcher &&
																			cargoPipelineEvent
																				.driver
																				.data
																				.attorneys
																				.length
																	"
																	class="main-info__item-info-card-list"
																>
																	<li
																		v-for="(attorney,
																		index) in cargoPipelineEvent
																			.driver
																			.data
																			.attorneys"
																		:key="
																			index
																		"
																		class="main-info__item-info-card-list-item"
																	>
																		<a
																			:href="
																				`/contractors/id${form.contractor.id}/attorney/id${attorney.id}`
																			"
																			target="_blank"
																			>Доверенность
																			№{{
																				attorney.number
																			}}</a
																		>
																		<span
																			v-if="
																				attorney.status ===
																					0
																			"
																			class="main-info__item-info-card-list-item-status bg-secondary"
																		>
																			На
																			рассмотрении</span
																		>
																		<span
																			v-else-if="
																				attorney.status ===
																					1
																			"
																			class="main-info__item-info-card-list-item-status bg-success"
																		>
																			Действует</span
																		>
																		<span
																			v-else-if="
																				attorney.status ===
																					2
																			"
																			class="main-info__item-info-card-list-item-status bg-danger"
																		>
																			Аннулирована</span
																		><br />
																		Действует
																		с
																		{{
																			attorney.dateStart
																		}}
																		по
																		{{
																			attorney.dateEnd
																		}}<br />
																		<span
																			v-if="
																				attorney.file
																			"
																		>
																			Загруженный
																			файл:
																			<a
																				:href="
																					'/files/' +
																						attorney
																							.file
																							.file
																							.guid
																				"
																				target="_blank"
																				@click.prevent="
																					downloadFile(
																						attorney
																							.file
																							.file
																					)
																				"
																				>{{
																					attorney
																						.file
																						.file
																						.originalName
																				}}</a
																			>
																		</span>
																	</li>
																</ol>
															</ul>
														</div>
													</div>
												</b-col>
											</b-row>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="fixed-buttons">
				<b-button
					type="button"
					variant="secondary"
					class="mr-2"
					:class="{ loading: loading }"
					@click="$router.go(-1)"
				>
					Назад
				</b-button>
				<!--b-button
          v-if="form.firstPointArrivalDatetime && form.firstPointLoadingDatetime && route.isDraft && !route.isReady && !(route.disclaimer && route.disclaimer.status === 0) && $store.getters.isAdminOrManager"
          type="button"
          variant="success"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="confirmApplyFlight"
        >
          Подать заявку на готовность к выполнению
        </b-button-->
				<b-button
					v-if="
						form.firstPointArrivalDatetime &&
							form.firstPointLoadingDatetime &&
							route.isDraft &&
							!(
								route.disclaimer &&
								route.disclaimer.status === 0
							) &&
							$store.getters.isAdminOrManager
					"
					type="button"
					variant="primary"
					class="mr-2"
					:class="{ loading: loading }"
					:disabled="loading"
					:href="`/flights/contractor/flight/id${flightId}`"
				>
					Редактировать
				</b-button>
				<!--b-button
          v-if="form.firstPointArrivalDatetime && form.firstPointLoadingDatetime && route.isDraft && !route.isReady && !route.disclaimer && $store.getters.isAdminOrManager"
          type="button"
          variant="danger"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="confirmRefusalFlight"
        >
          Отказаться от рейса
        </b-button-->
			</div>
		</div>

		<b-modal
			v-model="confirmApplyFlightModal.show"
			:title="confirmApplyFlightModal.title"
			class="modal-warning"
			cancel-title="Назад"
			ok-title="Да"
			@ok="applyFlight"
		>
			{{ confirmApplyFlightModal.desc }}
		</b-modal>
		<b-modal
			v-model="confirmRefusalFlightModal.show"
			:title="confirmRefusalFlightModal.title"
			class="modal-warning"
			cancel-title="Назад"
			:ok-disabled="!Boolean(confirmRefusalFlightModal.comment)"
			ok-title="Отправить"
			@ok="refusalFlight"
		>
			<p>{{ confirmRefusalFlightModal.desc }}</p>
			<b-form-textarea
				id="comment"
				v-model="confirmRefusalFlightModal.comment"
				type="text"
				name="comment"
				rows="4"
				:state="Boolean(confirmRefusalFlightModal.comment)"
				aria-describedby="input1LiveFeedback1"
				:placeholder="confirmRefusalFlightModal.placeholder"
				autocomplete="comment"
				no-resize
			/>
			<b-form-invalid-feedback id="input1LiveFeedback1">
				Это поле обязательно для заполнения
			</b-form-invalid-feedback>
		</b-modal>
	</div>
</template>

<script>
import ElSelectClearable from "../../components/ElSelectClearable.vue";
import RouteCounter from "@/components/RouteCounter.vue";
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import notifications from "../../components/mixins/notifications";
import { numberToRUFormat } from "@/components/mixins/helpers";
import {
	routeWayRead,
	contractorsDriverRead,
	contractorsContainerRead,
	contractorsRouteRead,
	contractorsRouteSetReady,
	contractorsRouteCreateDisclaimer,
	checkContractorsDriverByDriverId,
	downloadFile,
	contractorsTransportVehicleRead,
} from "../../services/api";
import moment from "moment";
import tz from "moment-timezone";

Vue.use(ClientTable);

export default {
	name: "ContractorFlightsView",
	components: {
		ClientTable,
		Event,
		ElSelectClearable,
		RouteCounter,
	},
	mixins: [validationMixin, notifications],
	props: ["flightId"],
	data() {
		return {
			routeCurrentState: {},
			optionalDimensions: [],
      comment: {
				id: null,
				value: null,
			},
			data: {
				responsible: {},
				driverOne: {
					attorneys: [],
					driverPhones: [],
				},
				driverTwo: {
					attorneys: [],
					driverPhones: [],
				},
				car: {
					containerTransportVehicle: false,
				},
				carContainer: [],
				trailer: {
					containerTransportVehicle: false,
				},
				trailerContainer: [],
				wrongTS: false,
			},
			transportationType: null,
			form: {
				cargoFlow: false,
				cargoPipelines: [],
				cargoPipelinePlacesEvent: {
					visible: false,
					value: null,
					options: [],
				},
				size: null,
				loadingUnloading: [],
				bodyType: [],
				mkadPass: false,
				retryDriver: false,
				firstPointArrivalDatetime: "",
				firstPointLoadingDatetime: "",
				freightSumm: null,
				accelerated: false,
				organizations: {
					value: null,
					name: "",
					options: [],
				},
				contractor: {
					options: [],
					value: "",
				},
				driverOne: {
					options: [],
					value: "",
				},
				driverTwo: {
					options: [],
					value: "",
				},
				car: {
					options: [],
					value: "",
				},
				carContainer: {
					options: [],
					value: [],
				},
				trailer: {
					options: [],
					value: "",
				},
				trailerContainer: {
					options: [],
					value: [],
				},
			},
			route: {
				code: "",
				name: "",
				type: "",
				disclaimer: null,
				auction: null,
				columns: [
					"rowNumber",
					"department.name",
					"department.mainWarehouse.address",
					"timeSummer",
					"distance",
				],
				transportation: {
					name: null,
					guid: null,
				},
				options: {
					headings: {
						rowNumber: "№",
						"department.name": "Наименование Отделения",
						"department.mainWarehouse.address":
							"Адрес точки Маршрута",
						timeSummer: "Ориентировочное время в пути",
						distance: "Расстояние",
					},
					sortable: [],
					filterable: [],
					sortIcon: {
						base: "fa",
						up: "fa-sort-asc",
						down: "fa-sort-desc",
						is: "fa-sort",
					},
					orderBy: {
						column: "rowNumber",
					},
					pagination: {
						chunk: 10,
						edge: true,
						nav: "fixed",
					},
					texts: {
						count:
							"Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись",
						first: "Первая",
						last: "Последняя",
						filter: "Фильтр:",
						filterPlaceholder: "Поисковый запрос",
						limit: "Записи:",
						page: "Страница:",
						noResults: "Нет совпадающих записей",
						filterBy: "Фильтр по {column}",
						loading: "Загрузка...",
						defaultOption: "Выбор {column}",
						columns: "Столбцы",
					},
				},
				points: [],
				movementPoints: [],
				fullDistance: "",
				theme: "bootstrap4",
			},
			feedBack: "secondary",
			loading: false,
			confirmApplyFlightModal: {
				show: false,
				title: "Предупреждение",
				desc: "",
			},
			confirmRefusalFlightModal: {
				show: false,
				title: "Отказ от рейса",
				desc: "",
				label: "",
				placeholder: "",
				comment: "",
			},
			useVuex: false,
			template: "default",
		};
	},
	validations: {
		form: {
			route: {
				options: [],
				value: "",
			},
			firstPointArrivalDatetime: "",
			firstPointLoadingDatetime: "",
			contractor: {
				options: [],
				value: "",
			},
			driver: {
				options: [],
				value: "",
			},
			car: {
				options: [],
				value: "",
			},
			trailer: {
				options: [],
				value: "",
			},
			container: {
				options: [],
				value: "",
			},
		},
	},
	computed: {
		getRouteStatus(status) {
			return (status) => {
				switch (status) {
					case 0:
						return "Ожидает прибытия";
						break;
					case 1:
						return "Прибыл (ожидает погрузки)";
						break;
					case 2:
						return "В Погрузке";
						break;
					case 3:
						return "Прибыл (ожидает погрузки/разгрузки)";
						break;
					case 4:
						return "В погрузке или разгрузке";
						break;
					case 5:
						return "Прибыл (ожидает разгрузку)";
						break;
					case 6:
						return "На разгрузке";
						break;
					case 7:
						return "Завершен";
						break;
					default:
						break;
				}
			};
		},
	},
	watch: {
		$route() {
			this.contractorsRouteRead(
				this.$store.state.user.ownerId,
				this.flightId
			);
		},
	},
	mounted() {
		if (this.$store.state.ownerType === "customer") {
			this.$router.push({
				path: "/flights/contractor/flight/id" + this.flightId,
			});
		}
		this.contractorsRouteRead(
			this.$store.state.user.ownerId,
			this.flightId
		);
	},
	methods: {
		downloadFile,
		formatDatetime(date) {
			return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
		},
		formatDatetimeWithOriginalTimezone(date) {
			const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
				.timeZone;
			return date
				? moment(date, "YYYY-MM-DDTHH:mm")
						.tz(clientTimezone)
						.format("DD.MM.YYYY HH:mm")
				: "";
		},
		async getContractorsDriverByDriverId(contractorId, driverId) {
			this.loading = true;
			const response = await checkContractorsDriverByDriverId(
				contractorId,
				driverId
			);
			if (response && response.status === 200) {
				return response.data.id;
			}
			this.loading = false;
		},
		async onRouteChange(value) {
			if (value) {
				this.loading = true;
				const response = await routeWayRead(value);
				if (response && response.status === 200) {
					this.route.name = response.data.name;
					this.route.type = response.data.transportationType.name;
					this.route.points = response.data.routeWayPoints.sort(
						(a, b) => a.rowNumber - b.rowNumber
					);
					if (
						this.route.transportation.guid ===
							"4b714ab4-4c44-4578-9a7a-06dbe77d8c45" ||
						this.route.transportation.guid ===
							"9ec087d0-917c-4fbb-b714-80ab7603fd6e"
					) {
						this.route.points.forEach((item, index) => {
							if (
								this.route.movementPoints[index].pointAddress &&
								this.route.movementPoints[index]
									.pointAddress !== ""
							) {
								item.department.mainWarehouse.address = this.route.movementPoints[
									index
								].pointAddress;
							}
						});
					}
					this.route.fullDistance = response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return accumulator + Number(currentValue.distance);
						}, 0)
						.toFixed(2);
				}
				this.loading = false;
			}
		},
		async onDriverOneChange(value) {
			if (value) {
				this.loading = true;
				if (value !== this.form.driverTwo.value) {
					const response = await contractorsDriverRead(
						this.$store.state.user.ownerId,
						value
					);
					if (response && response.status === 200) {
						const driver = {
							attorneys: [],
							driverPhones: [],
						};
						let fio =
							response.data.driver.surname +
							" " +
							response.data.driver.name;
						if (response.data.driver.patronymic) {
							fio = fio + " " + response.data.driver.patronymic;
						}
						driver.id = response.data.driver.id;
						driver.activeAttorney = response.data.activeAttorney;
						driver.driverAccessAuto =
							response.data.driver.driverAccessAuto;
						driver.driverAccessCargoFlow =
							response.data.driver.driverAccessCargoFlow;
						driver.driverAccessContainer =
							response.data.driver.driverAccessContainer;
						driver.status = response.data.driver.status;
						driver.inn = response.data.driver.inn;
						driver.fio = fio;
						if (response.data.attorneys.length) {
							driver.attorneys = response.data.attorneys.map(
								(item) => {
									const attorney = {};
									attorney.id = item.id;
									attorney.number = item.number;
									attorney.status = item.status;
									attorney.dateEnd = moment(
										item.dateEnd
									).format("DD.MM.YYYY");
									attorney.dateStart = moment(
										item.dateStart
									).format("DD.MM.YYYY");
									if (item.cancelComment) {
										attorney.cancelComment =
											item.cancelComment;
									}
									if (item.file) {
										attorney.file = item.file;
									}
									return attorney;
								}
							);
						}
						if (response.data.driverPhones.length) {
							driver.driverPhones = response.data.driverPhones.map(
								(item) => {
									const phone = {};
									phone.name = item.name;
									phone.number =
										"+" +
										item.countryCode.code +
										item.areaCode +
										item.localNumber;
									if (item.additionalNumber) {
										phone.number =
											phone.number +
											"(" +
											item.additionalNumber +
											")";
									}
									return phone;
								}
							);
						}
						this.data.driverOne = driver;
					}
				} else {
					this.form.driverOne.value = "";
					this.showError("Вы уже добавили этого водителя");
				}
				this.loading = false;
			}
		},
		async onDriverTwoChange(value) {
			if (value) {
				this.loading = true;
				if (value !== this.form.driverOne.value) {
					const response = await contractorsDriverRead(
						this.$store.state.user.ownerId,
						value
					);
					if (response && response.status === 200) {
						const driver = {
							attorneys: [],
							driverPhones: [],
						};
						let fio =
							response.data.driver.surname +
							" " +
							response.data.driver.name;
						if (response.data.driver.patronymic) {
							fio = fio + " " + response.data.driver.patronymic;
						}
						driver.id = response.data.driver.id;
						driver.activeAttorney = response.data.activeAttorney;
						driver.driverAccessAuto =
							response.data.driver.driverAccessAuto;
						driver.driverAccessCargoFlow =
							response.data.driver.driverAccessCargoFlow;
						driver.driverAccessContainer =
							response.data.driver.driverAccessContainer;
						driver.status = response.data.driver.status;
						driver.inn = response.data.driver.inn;
						driver.fio = fio;
						if (response.data.attorneys.length) {
							driver.attorneys = response.data.attorneys.map(
								(item) => {
									const attorney = {};
									attorney.id = item.id;
									attorney.number = item.number;
									attorney.status = item.status;
									attorney.dateEnd = moment(
										item.dateEnd
									).format("DD.MM.YYYY");
									attorney.dateStart = moment(
										item.dateStart
									).format("DD.MM.YYYY");
									if (item.cancelComment) {
										attorney.cancelComment =
											item.cancelComment;
									}
									if (item.file) {
										attorney.file = item.file;
									}
									return attorney;
								}
							);
						}
						if (response.data.driverPhones.length) {
							driver.driverPhones = response.data.driverPhones.map(
								(item) => {
									const phone = {};
									phone.name = item.name;
									phone.number =
										"+" +
										item.countryCode.code +
										item.areaCode +
										item.localNumber;
									if (item.additionalNumber) {
										phone.number =
											phone.number +
											"(" +
											item.additionalNumber +
											")";
									}
									return phone;
								}
							);
						}
						this.data.driverTwo = driver;
					}
				} else {
					this.form.driverTwo.value = "";
					this.showError("Вы уже добавили этого водителя");
				}
				this.loading = false;
			}
		},
		async onCargoPipelineDriverChange(
			value,
			indexCargoPipeline,
			indexCargoPipelineEvent
		) {
			this.loading = true;
			let contractorId = this.form.contractor.id;
			if (
				this.form.cargoPipelines[indexCargoPipeline]
					.cargoPipelineEvents[indexCargoPipelineEvent].partner
					.switcher &&
				this.form.cargoPipelines[indexCargoPipeline]
					.cargoPipelineEvents[indexCargoPipelineEvent].partner.value
			) {
				contractorId = this.form.cargoPipelines[indexCargoPipeline]
					.cargoPipelineEvents[indexCargoPipelineEvent].partner.value;
			}
			const response = await contractorsDriverRead(contractorId, value);
			if (response && response.status === 200) {
				const driver = {
					attorneys: [],
					driverPhones: [],
				};
				let fio =
					response.data.driver.surname +
					" " +
					response.data.driver.name;
				if (response.data.driver.patronymic) {
					fio = fio + " " + response.data.driver.patronymic;
				}
				driver.id = response.data.driver.id;
				driver.activeAttorney = response.data.activeAttorney;
				driver.driverAccessAuto = response.data.driver.driverAccessAuto;
				driver.driverAccessCargoFlow =
					response.data.driver.driverAccessCargoFlow;
				driver.driverAccessContainer =
					response.data.driver.driverAccessContainer;
				driver.status = response.data.driver.status;
				driver.inn = response.data.driver.inn;
				driver.fio = fio;
				if (response.data.attorneys && response.data.attorneys.length) {
					driver.attorneys = response.data.attorneys.map((item) => {
						const attorney = {};
						attorney.id = item.id;
						attorney.number = item.number;
						attorney.status = item.status;
						attorney.dateEnd = moment(item.dateEnd).format(
							"DD.MM.YYYY"
						);
						attorney.dateStart = moment(item.dateStart).format(
							"DD.MM.YYYY"
						);
						if (item.file) {
							attorney.file = item.file;
						}
						return attorney;
					});
				}
				if (response.data.driverPhones.length) {
					driver.driverPhones = response.data.driverPhones.map(
						(item) => {
							const phone = {};
							phone.name = item.name;
							phone.number =
								"+" +
								item.countryCode.code +
								item.areaCode +
								item.localNumber;
							if (item.additionalNumber) {
								phone.number =
									phone.number +
									"(" +
									item.additionalNumber +
									")";
							}
							return phone;
						}
					);
				}
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].driver.data = driver;
			}
			this.loading = false;
		},
		async onCargoPipelineCarChange(
			value,
			indexCargoPipeline,
			indexCargoPipelineEvent
		) {
			if (value) {
				this.loading = true;
				let contractorId = this.form.contractor.id;
				if (
					this.form.cargoPipelines[indexCargoPipeline]
						.cargoPipelineEvents[indexCargoPipelineEvent].partner
						.switcher &&
					this.form.cargoPipelines[indexCargoPipeline]
						.cargoPipelineEvents[indexCargoPipelineEvent].partner
						.value
				) {
					contractorId = this.form.cargoPipelines[indexCargoPipeline]
						.cargoPipelineEvents[indexCargoPipelineEvent].partner
						.value;
				}
				const response = await contractorsTransportVehicleRead(
					contractorId,
					value
				);
				if (response && response.status === 200) {
					const vehicle = {};
					vehicle.id = response.data.vehicle.id;
					vehicle.blockedSecurity =
						response.data.vehicle.blockedSecurity;
					vehicle.registrationNumber =
						response.data.vehicle.registrationNumber;
					vehicle.name = response.data.vehicle.model.name;
					vehicle.containerTransportVehicle =
						response.data.vehicle.model.containerTransportVehicle;
					vehicle.loadCapacity =
						response.data.vehicle.model.loadCapacity;
					vehicle.bodyHeight = response.data.vehicle.model.bodyHeight;
					vehicle.bodyWidth = response.data.vehicle.model.bodyWidth;
					vehicle.bodyDepth = response.data.vehicle.model.bodyDepth;
					vehicle.bodyVolume = response.data.vehicle.model.bodyVolume;
					vehicle.type = response.data.vehicle.model.type.name;
					if (response.data.vehicle.bodyType) {
						vehicle.bodyType = response.data.vehicle.bodyType.name;
					}
					this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents[
						indexCargoPipelineEvent
					].vehicle.data = vehicle;
				}
				this.loading = false;
			}
		},
		async getCarContractorsContainerData(contractorId, containerId) {
			this.loading = true;
			const response = await contractorsContainerRead(
				contractorId,
				containerId
			);
			if (response && response.status === 200) {
				const containerData = {};
				containerData.number = response.data.number;
				containerData.name = response.data.name;
				containerData.height = response.data.height;
				containerData.width = response.data.width;
				containerData.depth = response.data.depth;
				containerData.maximumPayload = response.data.maximumPayload;
				containerData.volume = response.data.volume;
				this.data.carContainer.push(containerData);
			}
			this.loading = false;
		},
		onCarContainerChange(value) {
			if (value.length) {
				this.data.carContainer = [];
				value.forEach((container) => {
					this.getCarContractorsContainerData(
						this.$store.state.user.ownerId,
						container
					);
				});
			}
		},
		async getTrailerContractorsContainerData(contractorId, containerId) {
			this.loading = true;
			const response = await contractorsContainerRead(
				contractorId,
				containerId
			);
			if (response && response.status === 200) {
				const containerData = {};
				containerData.number = response.data.number;
				containerData.name = response.data.name;
				containerData.height = response.data.height;
				containerData.width = response.data.width;
				containerData.depth = response.data.depth;
				containerData.maximumPayload = response.data.maximumPayload;
				containerData.volume = response.data.volume;
				this.data.trailerContainer.push(containerData);
			}
			this.loading = false;
		},
		onTrailerContainerChange(value) {
			if (value.length) {
				this.data.trailerContainer = [];
				value.forEach((container) => {
					this.getTrailerContractorsContainerData(
						this.$store.state.user.ownerId,
						container
					);
				});
			}
		},
		async contractorsRouteRead(contractorId, routeId) {
			this.loading = true;
			const response = await contractorsRouteRead(contractorId, routeId);
			if (response && response.status === 200) {
        if (response.data.comment) {
					this.comment.value = response.data.comment.comment;
					this.comment.id = response.data.comment.id;
				}
				if (response.data.auctionRoute) {
					this.route.auction = response.data.auctionRoute.auction;
					if (response.data.auctionRoute.dimension) {
						this.form.size =
							response.data.auctionRoute.dimension.name;
					}
					if (response.data.auctionRoute.loadingTypes.length > 0) {
						this.form.loadingUnloading =
							response.data.auctionRoute.loadingTypes;
					}
					if (response.data.auctionRoute.bodyTypes.length > 0) {
						this.form.bodyType =
							response.data.auctionRoute.bodyTypes;
					}
					this.form.mkadPass = response.data.auctionRoute.mkadPass;
					this.form.retryDriver =
						response.data.auctionRoute.retryDriver;
					this.optionalDimensions = response.data.auctionRoute.optionalDimensions.filter(
						(optionalDimension) => optionalDimension.active
					);
				}
				if (response.data.contractorDisclaimer) {
					this.route.disclaimer = response.data.contractorDisclaimer;
				}
				if (response.data.organization) {
					this.form.organizations.value =
						response.data.organization.id;
					this.form.organizations.name =
						response.data.organization.name;
				}
				this.route.id = response.data.id;
				this.form.cargoFlow = response.data.cargoFlow;
				this.route.routeCode = response.data.routeCode;
				this.route.isDraft = response.data.isDraft;
				this.route.isDirty = response.data.isDirty;
				this.route.isReady = response.data.isReady;
				this.route.isCancel = response.data.isCancel;
				const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
					.timeZone;
				if (response.data.planDateOfFirstPointArrive) {
					this.form.firstPointArrivalDatetime = moment(
						response.data.planDateOfFirstPointArrive,
						"YYYY-MM-DDTHH:mm:ss"
					)
						.tz(clientTimezone)
						.format("DD.MM.YYYY HH:mm");
				}
				if (response.data.planDateOfFirstPointLoading) {
					this.form.firstPointLoadingDatetime = moment(
						response.data.planDateOfFirstPointLoading,
						"YYYY-MM-DDTHH:mm:ss"
					)
						.tz(clientTimezone)
						.format("DD.MM.YYYY HH:mm");
				}
				if (response.data.freightSumm) {
					this.form.freightSumm = response.data.freightSumm;
				}
				if (response.data.routeCurrentState) {
					this.routeCurrentState = response.data.routeCurrentState;
					if (
						response.data.routeCurrentState.currentPoints &&
						response.data.routeCurrentState.currentPoints.length > 0
					) {
						this.routeCurrentState.currentPoints = response.data.routeCurrentState.currentPoints.sort(
							(a, b) => a.rowNumber - b.rowNumber
						);
					}
				}
				this.route.name = response.data.routeWay.name;
				if (response.data.transportation.name) {
					this.route.transportation.name =
						response.data.transportation.name;
				}
				if (response.data.transportation.guid) {
					this.route.transportation.guid =
						response.data.transportation.guid;
				}
				if (response.data.transportationType) {
					this.transportationType =
						response.data.transportationType.name;
				}
				if (response.data.routeWay.transportationType) {
					this.route.type =
						response.data.routeWay.transportationType.name;
				}
				if (response.data.movementPoints) {
					this.route.movementPoints = response.data.movementPoints.sort(
						(a, b) => a.rowNumber - b.rowNumber
					);
				}
				this.onRouteChange(response.data.routeWay.id);
				this.form.accelerated = response.data.boostFlag;
				if (response.data.naRouteOwner) {
					this.data.responsible = response.data.naRouteOwner;
				}
				this.form.contractor = response.data.contractor;
				if (response.data.driverOne) {
					const contractorsDriverId = await this.getContractorsDriverByDriverId(
						response.data.contractor.id,
						response.data.driverOne.id
					);
					this.form.driverOne.options = [];
					let fio =
						response.data.driverOne.surname +
						" " +
						response.data.driverOne.name;
					if (response.data.driverOne.patronymic) {
						fio = fio + " " + response.data.driverOne.patronymic;
					}
					this.form.driverOne.options.push({
						value: contractorsDriverId,
						text: fio,
					});
					this.form.driverOne.value = contractorsDriverId;
					this.onDriverOneChange(contractorsDriverId);
				}
				if (response.data.driverTwo) {
					const contractorsDriverId = await this.getContractorsDriverByDriverId(
						response.data.contractor.id,
						response.data.driverTwo.id
					);
					this.form.driverTwo.options = [];
					let fio =
						response.data.driverTwo.surname +
						" " +
						response.data.driverTwo.name;
					if (response.data.driverTwo.patronymic) {
						fio = fio + " " + response.data.driverTwo.patronymic;
					}
					this.form.driverTwo.options.push({
						value: contractorsDriverId,
						text: fio,
					});
					this.form.driverTwo.value = contractorsDriverId;
					this.onDriverTwoChange(contractorsDriverId);
				}
				if (response.data.transport) {
					this.data.car.registrationNumber =
						response.data.transport.registrationNumber;
					this.data.car.blockedSecurity =
						response.data.transport.blockedSecurity;
					this.data.car.name = response.data.transport.model.name;
					this.form.car.options = [];
					this.form.car.options.push({
						value: response.data.transport.id,
						text: response.data.transport.model.name,
						errored: response.data.transport.blockedSecurity,
					});
					this.form.car.value = response.data.transport.id;
					this.data.car.id = response.data.transport.id;
					this.data.car.containerTransportVehicle =
						response.data.transport.model.containerTransportVehicle;
					this.data.car.loadCapacity =
						response.data.transport.model.loadCapacity;
					this.data.car.bodyHeight =
						response.data.transport.model.bodyHeight;
					this.data.car.bodyWidth =
						response.data.transport.model.bodyWidth;
					this.data.car.bodyDepth =
						response.data.transport.model.bodyDepth;
					this.data.car.bodyVolume =
						response.data.transport.model.bodyVolume;
					this.data.car.type =
						response.data.transport.model.type.name;
					if (response.data.transport.bodyType) {
						this.data.car.bodyType =
							response.data.transport.bodyType.name;
					}
				}
				if (response.data.trailer) {
					this.data.trailer.registrationNumber =
						response.data.trailer.registrationNumber;
					this.data.trailer.blockedSecurity =
						response.data.trailer.blockedSecurity;
					this.data.trailer.name = response.data.trailer.model.name;
					this.form.trailer.options = [];
					this.form.trailer.options.push({
						value: response.data.trailer.id,
						text: response.data.trailer.model.name,
						errored: response.data.trailer.blockedSecurity,
					});
					this.form.trailer.value = response.data.trailer.id;
					this.data.trailer.id = response.data.trailer.id;
					this.data.trailer.containerTransportVehicle =
						response.data.trailer.model.containerTransportVehicle;
					this.data.trailer.loadCapacity =
						response.data.trailer.model.loadCapacity;
					this.data.trailer.bodyHeight =
						response.data.trailer.model.bodyHeight;
					this.data.trailer.bodyWidth =
						response.data.trailer.model.bodyWidth;
					this.data.trailer.bodyDepth =
						response.data.trailer.model.bodyDepth;
					this.data.trailer.bodyVolume =
						response.data.trailer.model.bodyVolume;
					this.data.trailer.type =
						response.data.trailer.model.type.name;
					if (response.data.trailer.bodyType) {
						this.data.trailer.bodyType =
							response.data.trailer.bodyType.name;
					}
				}
				if (response.data.routeContainers.length) {
					response.data.routeContainers.forEach((container) => {
						if (
							container.vehicle.id === response.data.transport.id
						) {
							this.form.carContainer.options.push({
								value: container.container.id,
								text: container.container.name,
							});
							this.form.carContainer.value.push(
								container.container.id
							);
						} else if (
							container.vehicle.id === response.data.trailer.id
						) {
							this.form.trailerContainer.options.push({
								value: container.container.id,
								text: container.container.name,
							});
							this.form.trailerContainer.value.push(
								container.container.id
							);
						}
					});
					this.onCarContainerChange(this.form.carContainer.value);
					this.onTrailerContainerChange(
						this.form.trailerContainer.value
					);
				}
				if (
					response.data.warnings &&
					response.data.warnings.dimension &&
					response.data.warnings.dimension.length > 0
				) {
					this.data.wrongTS = true;
				}
				if (response.data.cargoPipelines) {
					response.data.cargoPipelines.sort(
						(a, b) => a.rowNumber - b.rowNumber
					);
					const cargoPipelineGuide = {};
					response.data.cargoPipelines.forEach((cargoPipeline) => {
						if (
							Object.keys(cargoPipelineGuide).length === 0 ||
							!Object.keys(cargoPipelineGuide).find(
								(item) =>
									item ==
									cargoPipeline.cargoPipelinePlaceOfEvent.id
							)
						) {
							cargoPipelineGuide[
								cargoPipeline.cargoPipelinePlaceOfEvent.id
							] = Object.keys(cargoPipelineGuide).length;
							this.form.cargoPipelines.push({
								cargoPipelinePlaceOfEvent: {
									id:
										cargoPipeline.cargoPipelinePlaceOfEvent
											.id,
									name:
										cargoPipeline.cargoPipelinePlaceOfEvent
											.name,
								},
								cargoPipelineEvents: [],
							});
						}
					});
					response.data.cargoPipelines.forEach((cargoPipeline) => {
						const partner = {
							switcher: false,
							value: null,
							options: [],
							name: null,
						};
						if (cargoPipeline.partner) {
							partner.switcher = true;
							partner.value = cargoPipeline.partner.id;
							partner.name = cargoPipeline.partner.name;
						}
						this.form.cargoPipelines[
							cargoPipelineGuide[
								cargoPipeline.cargoPipelinePlaceOfEvent.id
							]
						].cargoPipelineEvents.push({
							formVisible: false,
							rowNumber: cargoPipeline.rowNumber,
							value: cargoPipeline.cargoPipelineEvent.id,
							name: cargoPipeline.cargoPipelineEvent.name,
							cargoPipelineEventDate: moment(
								cargoPipeline.cargoPipelineEventDate,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format(),
							partner: partner,
							vehicle: {
								value:
									cargoPipeline.vehicle.contractorVehicleId,
								options: [],
								data: {},
							},
							driver: {
								value: cargoPipeline.driver.contractorDriverId,
								options: [],
								data: {},
							},
						});
						const newCargoPipelineIndex =
							cargoPipelineGuide[
								cargoPipeline.cargoPipelinePlaceOfEvent.id
							];
						const newCargoPipelineEventIndex =
							this.form.cargoPipelines[newCargoPipelineIndex]
								.cargoPipelineEvents.length - 1;
						this.onCargoPipelineCarChange(
							cargoPipeline.vehicle.contractorVehicleId,
							newCargoPipelineIndex,
							newCargoPipelineEventIndex
						);
						this.onCargoPipelineDriverChange(
							cargoPipeline.driver.contractorDriverId,
							newCargoPipelineIndex,
							newCargoPipelineEventIndex
						);
					});
				}
			}
			this.loading = false;
		},
		chkState(val) {
			const field = this.$v.form[val];
			if (!field.$dirty) {
				return null;
			} else if (field.$invalid) {
				return false;
			} else {
				return true;
			}
		},
		findFirstError(component = this) {
			if (component.state === false) {
				if (component.$refs.input) {
					component.$refs.input.focus();
					return true;
				}
				if (component.$refs.check) {
					component.$refs.check.focus();
					return true;
				}
			}
			let focused = false;
			component.$children.some((child) => {
				focused = this.findFirstError(child);
				return focused;
			});

			return focused;
		},
		validate() {
			this.$v.$touch();
			this.$nextTick(() => this.findFirstError());
			return this.isValid;
		},
		async applyFlight() {
			this.loading = true;
			const response = await contractorsRouteSetReady(
				this.$store.state.user.ownerId,
				this.flightId
			);
			if (response && response.status === 200) {
				this.showSuccess(
					"Заявка на готовность к выполнению рейса подана"
				);
				this.$router.push({ path: "/flights/contractor" });
			}
			this.loading = false;
		},
		confirmApplyFlight() {
			this.confirmApplyFlightModal = {
				show: true,
				title: "Предупреждение",
				desc:
					"Вы уверены что хотите подать заявку на готовность к выполнению рейса? В дальнейшем изменения внутри рейса будут невозможны.",
			};
		},
		async refusalFlight() {
			this.loading = true;
			const response = await contractorsRouteCreateDisclaimer(
				this.$store.state.user.ownerId,
				this.flightId,
				this.confirmRefusalFlightModal.comment
			);
			if (response && response.status === 201) {
				this.showSuccess("Заявка на отказ от выполнения рейса подана");
				this.$router.go();
			}
			this.loading = false;
		},
		confirmRefusalFlight() {
			this.confirmRefusalFlightModal = {
				show: true,
				title: "Отказ от рейса",
				desc:
					"Для того, чтобы отказаться от выполнения рейса, необходимо указать причину отказа. Пожалуйста, опишите ее ниже.",
				placeholder: "Укажите причину",
			};
		},
		numberRUFormat(value) {
			return numberToRUFormat(value);
		},
	},
};
</script>

<style lang="scss">
.VueTables__table {
	margin-bottom: 2rem;
}
</style>
